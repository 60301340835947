<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="title-content-sub">
        <div class="title">合同台账上传</div>
        <ProgressIndicator class="progressIndicator" :steps="steps"
                           :current="currentStepIndex"></ProgressIndicator>
      </div>
    </div>
    <div class="section-box">
      <div class="section-head">
        <div>印花税统计表</div>

      </div>
      <YinhuashuiTable class="table" :table="table" ref="YinhuashuiTable"></YinhuashuiTable>
    </div>
    <Footer>
      <div class="footer">
        <router-link
          :to="{name:'util-yinHuaShui-uploader'}"
          class="btn btn-default btn-next btn-right-none"

        >
          取消
        </router-link>

        <button class="btn btn-primary btn-next" @click="handleBtnExportResultClick">
          导出转换结果
        </button>
      </div>
    </Footer>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import YinhuashuiTable from "@/pages/util/yinHuaShui/components/YinhuashuiTable";
import {exportContract, getContSummaryList} from "@/api/table";
import ProgressIndicator from "@/pages/components/ProgressIndicator";
import Footer from "@/pages/Test/ChooseTable/components/Footer";
import {saveAs} from "file-saver";
import axios from "axios";
import {Message} from "element-ui";

export default {
  name: "YinHuaShui",
  props: {
    programId: String
  },
  components: {
    BreadCrumb,
    YinhuashuiTable,
    ProgressIndicator,
    Footer
  },
  async mounted() {
    const res = await getContSummaryList({programId: this.programId});

    if (res.code == 200) {
      this.table = res.data
    }

  }, data() {
    return {
      steps: [
        "上传文件",
        "分类数据",
        "分类结果"
      ],
      currentStepIndex: 2,
      table: []
    }
  },
  methods: {
    //导出按钮点击处理函数
    async handleBtnExportResultClick() {
      const result = this.$refs.YinhuashuiTable.getResult();
      const summaryData = JSON.stringify(result)
      const res = await exportContract({programId:this.programId,summaryData});
      saveAs(res.data,"印花税统计表.xls")
    }
  }
}
</script>

<style lang="stylus" scoped>
.table {
  width 100%;
  padding 0
  box-shadow: 0px 4px 8px 0px #eee
  border-radius 8px
  overflow hidden
}

.page-wrapper {
  padding-bottom: 84px;

  .section-head {
    display: flex;

    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 16px;
    margin-top 16px
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8)
  }

  .section-box {
    padding 16px 32px 32px
  }

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

    .title-content-sub {
      margin: 8px 30px;
      display flex
      position relative
      flex-direction row
      align-items center

      .title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }

      .progressIndicator {
        position absolute;
        left 50%
        top 50%
        transform translate(-50%, -50%)
      }

    }
  }
}

.btn-next {
  padding 12px 18px
  font-size: 16px;
  min-width 96px
}
</style>
