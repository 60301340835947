<template>
  <div class="processContainer">
    <template v-for="(step,index) in steps">
      <div class="sequence-radio" :key="index" :class="{active:current>=index}">
        <div class="text">{{ index + 1 }}</div>
      </div>
      <div class="process1" :key="index+'process1'" :class="{active:current>=index}">{{ step }}</div>
      <div class="process-status process-statusLine" v-if="index<steps.length-1" :key="index+'b'"></div>
    </template>


  </div>
</template>

<script>
export default {
  name: "ProgressIndicator",
  props: {
    steps: Array,
    current: Number
  }
}
</script>

<style lang="stylus" scoped>
.processContainer {
  display: flex;
  align-items center
  top: 14px;

  .sequence-radio {
    border-radius 15px;
    background rgba(0, 0, 0, 0.4)
    color #fff
    font-size 12px
    line-height 1px
    font-weight bold
    width 21px
    height 21px
    display flex;
    align-items center;
    justify-content center
    margin-right 8px
    transition 0.5s all;

    .text {
      font-family: ArialMT;
      position relative;
      font-size 12px
      font-weight bold
    }
  }

  .sequence-radio.active {
    background rgba(47, 84, 235, 1)
  }
}
.process1{
  color: rgba(0, 0, 0, 0.4)
  font-weight bold
  font-size: 16px;
  position: relative
  top: -1px
}
.process1.active{
  color rgba(47, 84, 235, 1)
}
.process-status {
  width: 120px;
  height: 1px;
  background: rgba(217, 217, 217, 1);
  margin: 0 24px;
}

.process-statusLine {
  background: rgba(217, 217, 217, 1)

}

</style>
